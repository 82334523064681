import color from "./color/index.js";
import { fontSize, fontFamily } from "./font/index.js";
import { lightGradient } from "./gradient/index.js";
import { breakpoints } from "./media-queries/index.js";
import spacing from "./spacing/index.js";
const ratio = (pxValue) => {
    const ratio = 1.05;
    const value = pxValue * ratio;
    return `${value}px`;
};
const defaultDimensions = {
    screenHeight: 0,
    screenWidth: 0,
};
const defaultSafeAreaInsets = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
};
export const theme = {
    ratio,
    dimensions: defaultDimensions,
    safeAreaInsets: defaultSafeAreaInsets,
    color,
    gradient: lightGradient,
    spacing,
    fontSize,
    fontFamily,
    breakpoints,
};
